import React from "react"
import { Container } from "react-bootstrap"
import "./BannerNoBg.scss"
import moment from "moment"
import Share from "../share/share"
import SiteBreadcrumbs from "../Breadcrumb/site-breadcrumbs"
const NewsIntro = ({ title, description, ctas, date, author }) => {
  return <>
    <SiteBreadcrumbs style="landing-breadcrumbs" alias="latest-property-news" menulabel={title}/>
    <Container className="banner-nobg-wrapper">
      <div className="banner-nobg-container">
        <h1 className="title">
          {title}
        </h1>
        <div className="news-head">
            {/* <div className="news-date">{moment(date).format("Do MMMM YYYY")}<span>/</span></div> */}
            <div className="news-author">By {author.Name}<span>/</span></div>
            <div className="news-share">
              {/* <i className="icon-share"></i>Share */}
              <Share iconclass="icon-share" text="Share"/>
            </div>
        </div>        
      </div>
    </Container>
  </>
}

export default NewsIntro
