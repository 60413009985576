import React from "react"
import { Container, Col, Row } from "react-bootstrap"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./assets/styles/_index.scss"
import OtherNewsSliderCard from "./OtherNewsSliderCard"
import { OtherNews } from "../../queries/common_use_query"
const OtherNewsSlider = props => {
  const {
    loading: loading,
    error: error,
    data: news_data,
  } = OtherNews(props.id)
  const news = news_data && news_data.newsEvents

  // Slider settings
  let settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 3,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
    ],
  }
  // Slider settings

  return (
    <section className="our-people-slider-wrapper other-news-slider">
      <Container>
        <Row>
          <Col>
            <h2 className="our-people-heading">
              Other news that may interest you
            </h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <Slider className="our-people-slider" {...settings}>
              {news &&
                news.length > 0 &&
                news.map(news => <OtherNewsSliderCard news={news} />)}
            </Slider>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default OtherNewsSlider
