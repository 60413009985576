import React from "react"
import "./assets/styles/_index.scss"
import { Link } from "gatsby"
import GetGGFXImage from "../common/site/get-ggfx-image"

const OtherNewsSliderCard = ({ news }) => {
  if (!news) {
    return null
  }
  let processedImages = JSON.stringify({});
  if (news?.imagetransforms?.Tile_Image_Transforms) {
    processedImages = news.imagetransforms.Tile_Image_Transforms;
  }
  return (
    <div className="our-people-slide">
      <div className="our-people-img-zoom">
        <Link to={`/about/latest-property-news/${news.URL}/`} >
          <GetGGFXImage imagename={"news-events.Tile_Image.tileimg"} imagesource={news.Tile_Image} fallbackalt={news.Title} imagetransformresult=
            {processedImages} id={news.id} />
        </Link>
        <div className="our-people-slide-display-adderss-wrapper d-flex justify-content-end">
          <Link to={`/about/latest-property-news/${news.URL}/`} className="btn-link">
            <i className="icon icon-track-slider-link-arrow"></i>
          </Link>
        </div>
      </div>
      <div className="our-people-slide-display-address">
        <span><Link to={`/about/latest-property-news/${news.URL}/`}>{news.Title}</Link></span>
      </div>
    </div>
  )
}

export default OtherNewsSliderCard
