/**
 * External dependencies
 */
import React from "react";
import {
	FacebookShareButton,
	LinkedinShareButton,
	TwitterShareButton,
} from "react-share";

const NewsBottomShare = (props) => {

  // Social share

	const shareurl = typeof window !== 'undefined' ? window.location.href : ''
	
	const [Shareicons,setShareicons] = React.useState(false);
	
	const openShareicons = () => {
	setShareicons(true);
	if(Shareicons === true) {
		setShareicons(false);
	}
	}

	

	const trackerShare = (event) => {    
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		'event': 'Share - social icons',
		'formType': event + ' - ' +shareurl,
		'formId': 'Share',
		'formName': 'Share',
		'formLabel': 'Share'
	});    
	}
	// Social share

  return (
    <div className={"share social-share-wrapper"}>
        <div onClick={openShareicons} className="property-share-social-icons mobile-details-socila-share">
          <button aria-label="instagram" class="react-share__ShareButton">
            <i className="icon-insta-white"></i>
          </button>
          <FacebookShareButton onClick={()=>trackerShare('FacebookShareButton')} url={shareurl} className="my-share-button facebook-share">
            <i className="icon-facebook"></i>
          </FacebookShareButton>
          <TwitterShareButton onClick={()=>trackerShare('TwitterShareButton')} url={shareurl} className="my-share-button twitter-share">
            <i className="icon-twitter"></i>
          </TwitterShareButton>
          <LinkedinShareButton onClick={()=>trackerShare('LinkedinShareButton')} url={shareurl} className="my-share-button linkedin-share">
            <i className="icon-linkedin"></i>
          </LinkedinShareButton>               
        </div>
      
      
    </div>
  )
}

export default NewsBottomShare
