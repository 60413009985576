import { graphql } from "gatsby"
import React from "react"
import NewsIntro from "../components/BannerNoBg/NewsIntro"
import "bootstrap/dist/css/bootstrap.min.css"
import "../scss/global.scss"
import Header from "../components/Header/Header"
import NewsDetails from "../components/AreaguideDetails/NewsDetails"
import Footer from "../components/Footer/Footer"
import FullWidthBlueBanner from "../components/SearchBanner/FullwidthBlueBanner"
import OtherNewsSlider from "../components/OtherNews/OtherNewsSlider"
import SEO from "../components/Seo/seo"

const NewsDetailPage = ({ data }) => {
  const news = data.glstrapi.newsEvent
  return (
    <div className="areaguide-detail-wrapper news-detail-wrapper">
      <SEO
        title={news.Meta_Title ? news.Meta_Title : news.Title}
        description={news.Meta_Description ? news.Meta_Description : `Read about ${news.Title} here and subscribe to our newsletter to stay up-to-date about everything going on at Sandfords Estate Agents.`}
      />
      <Header />
      <NewsIntro
        title={news.Title}
        description={news.News_Content_Above}
        date={news.News_Published_Date}
        author ={news.Select_Author}
      />
      <NewsDetails news={news} />  
      <OtherNewsSlider id={news.id}/>
      <FullWidthBlueBanner type="getintouch"/>    
      <Footer />
    </div>
  )
}

export default NewsDetailPage

export const pageQuery = graphql`
  query GetNewsPage($articleId: ID!) {
    glstrapi {
        newsEvent(id: $articleId, publicationState: LIVE) {
            Title
            Meta_Title
            Meta_Description
            id
            Banner_Image {
                url
                alternativeText
            }
            Select_Author {
                Name
            }
            News_Content_Below
            News_Content_Above
            News_Published_Date
            imagetransforms
        }
    }
  }
`
