import React from "react"
import { Accordion, Container } from "react-bootstrap"
import parse from "html-react-parser"

import "./AreaguideDetails.scss"
import DetailPageGetInTouch from "../DetailPageGetInTouch/DetailPageGetInTouch"
import NewsBottomShare from "../share/news-bottom-share"
import GetGGFXImage from "../common/site/get-ggfx-image"
const AreaguideDetails = ({ news }) => {
    let processedImages = JSON.stringify({});
    if (news?.imagetransforms?.Banner_Image_Transforms) {
        processedImages = news.imagetransforms.Banner_Image_Transforms;
    }
    return (
        <Container className="areaguide-details-section">
            <div className="img-section banner-img">
                <GetGGFXImage imagename={"news-events.Banner_Image.bannerimg"} imagesource={news.Banner_Image} fallbackalt={news.Title} imagetransformresult={processedImages} id={news.id}/>                  
            </div>
            {news.News_Content_Above && (
                <div className="text-section">{parse(news.News_Content_Above)}</div>
            )}
            <DetailPageGetInTouch content="show" />
            {news.News_Content_Below && (
                <div className="text-section">{parse(news.News_Content_Below)}</div>
            )}
            <div className="share-post">
                <h3>Share this Post</h3>
                <NewsBottomShare/>
            </div>
        </Container>
        
    )
}

export default AreaguideDetails
